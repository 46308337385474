import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';

import { Resource } from './Resource';

const useStyles = makeStyles((theme) => ({
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		marginBottom: '1rem',
		textAlign: 'center',
	},
  resourcesContainer: {
    position: 'relative',
  },
  imgLeft: {
    position: 'absolute',
    left: '-9px',
    top: '40px',
  },
  imgRight: {
    position: 'absolute',
    right: '-20px',
    bottom: '0px',
  },
}));

export const Resources = ({ thankyou }) => {
	const classes = useStyles();
  const lg = useMediaQuery('(max-width: 1279px)');

  const { featuredResources, featuredResourcesHeader, _id, circleImageLeft, circleImageRight } = thankyou;
	return (
    <Grid 
      container
      direction='row'
      justifyContent='center'
      className={classes.resourcesContainer}>
      {lg ? 
       null 
      : (
      <GatsbyImage
        image={circleImageLeft?.asset?.gatsbyImageData}
        className={classes.imgLeft}
      />
      )}
      <div style={{ zIndex: 100 }}>
        <Grid
          container
          item
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={1}>
          <Grid item>
            <Typography variant='h2' className={classes.header}>
              {featuredResourcesHeader}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction='row'
          justifyContent='center'
          style={{ padding: '2rem 0' }}>
          {featuredResources.map((resource, index) => (
            <Resource key={index} resource={resource} _id={_id} />
          ))}
        </Grid>
      </div>
      {lg ? 
       null 
      : (
      <GatsbyImage
        image={circleImageRight?.asset?.gatsbyImageData}
        className={classes.imgRight}
      />
      )}
    </Grid>

	);
};
