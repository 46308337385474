import React from 'react';
// import ReactPlayer from 'react-player/lazy';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid, useMediaQuery } from '@material-ui/core';

const Video = loadable(() => import('../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	closeIcon: {
		color: theme.workwaveBlue,
		fontSize: 50,
		margin: '1rem',
		marginBottom: '0',
		position: 'relative',
		bottom: '20px',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
	},
	playerWrapper: {
		position: 'relative',
		paddingTop: '57.25%',
		minHeight: '100%',
		minWidth: '100%',
		// border: '1px solid #d8dee0',
		// marginTop: '.5rem',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
}));

export const ThumbnailFeatureModal = ({
	open,
	wistiaLink,
	setFeatureModalOpen,
	feature,
}) => {
	const classes = useStyles();

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					maxWidth='md'
					open={open}
					onClose={(e) => setFeatureModalOpen(false)}
					BackdropComponent={Backdrop}>
					{/* <Grid container item direction='row' justify='flex-end'>
						<CloseIcon
							onClick={(e) => setHeroModalOpen(false)}
							className={classes.closeIcon}
						/>
					</Grid> */}
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						item
						style={{ height: '90vh', width: '90vw' }}
						xs>
						<div className={classes.playerWrapper}>
							{/* <ReactPlayer
								url={wistiaLink}
								className={classes.reactPlayer}
								height='100%'
								width='100%'
								controls={true}
							/> */}
							<Video
								url={wistiaLink}
								height='100%'
								width='100%'
								controls={true}
								feature={feature}
							/>
						</div>
					</Grid>
				</Dialog>
			</div>
		)
	);
};
