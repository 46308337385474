import React, { useContext } from 'react';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { MainFeature } from './MainFeature';
import { Resources } from './Resources';
import { CustomerLogos } from '../CustomerLogos';
import FeatureThumbnailContext from '../../context/FeatureThumbnailContext';

const useStyles = makeStyles((theme) => ({
	container: {
		paddingBottom: '16em',
		[theme.breakpoints.down('lg')]: {
			paddingTop: '4rem',
			paddingBottom: '4rem',
		},
	},
}));

export const ThankYouBody = ({ thankyou }) => {
	const classes = useStyles();
	const {
		_id,
		featureHeader,
		_rawFeatureBody,
		videoVariant,
		featureImage,
		// svgVariant,
		thumbnailVideoUrl,
		fileDownload,
		downloadCTAText,
		shareText,
		socialAssets,
		featuredResourcesHeader,
		featuredResources,
		customerLogosHeader,
		customerLogos,
	} = thankyou;

	const { handleSelectThumbnail } = useContext(FeatureThumbnailContext);

	return (
		<>
			<Container className={classes.container}>
				{!!featureHeader ? (
					<MainFeature
						featureHeader={featureHeader}
						_rawFeatureBody={_rawFeatureBody}
						videoVariant={videoVariant}
						featureImage={featureImage}
						// svgVariant={svgVariant}
						fileDownload={fileDownload}
						downloadCTAText={downloadCTAText}
						shareText={shareText}
						socialAssets={socialAssets}
					/>
				) : null}

				{!!featuredResources.length ? <Resources thankyou={thankyou} /> : null}

				{!!customerLogos.length ? (
          <div style={{ marginBottom: '2rem', }}>
            <CustomerLogos
              customerLogosArray={customerLogos}
              customerLogosHeader={customerLogosHeader}
            />
          </div>
				) : null}
			</Container>
		</>
	);
};
